
import { defineComponent } from 'vue'
import * as api from '../utils/api'
export default defineComponent({
	name: 'Info',
	props: ['role'],
	data: function () {
		return {
			infos: []
		}
	},
	mounted: async function () {
		try {
			const infos = await api.get(`/v1/${this.role}/info`)
			this.infos = infos
		} catch {
			console.error('info get error')
		}
	},
})
