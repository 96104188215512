import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_alert = _resolveComponent("n-alert")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infos, (i) => {
    return (_openBlock(), _createElementBlock("div", {
      style: {"padding":"5px"},
      key: i.id
    }, [
      _createVNode(_component_n_alert, {
        title: i.title,
        type: "info"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(i.contents), 1)
        ]),
        _: 2
      }, 1032, ["title"])
    ]))
  }), 128))
}